<template>
  <div class="relative flex items-center gap-4" :class="className">
    <div
      class="flex rounded-2xl bg-[#191919] md:py-0 [&>div:first-child>a]:rounded-l-2xl [&>div:last-child>a]:rounded-r-2xl [&>div>a]:!px-3 [&>div>a]:md:!px-4"
    >
      <WalletMenu />
      <template v-if="isWalletConnected && !isTutorialOpened">
        <div class="mx-auto my-2 min-h-full border-r border-solid border-white/5" />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import WalletMenu from './WalletMenu.vue'
import { useSwapkitWalletStore } from '~/store/swapkitWalletStore'

interface IProps {
  className?: string
}
defineProps<IProps>()

const swapkitWalletStore = useSwapkitWalletStore()
const { isWalletConnected } = storeToRefs(swapkitWalletStore)

const route = useRoute()

const isTutorialOpened = computed(
  () => route.path.startsWith('tutorial') || route.path.startsWith(`/tutorial`),
)
</script>
